import {Routes} from '@angular/router';

import { AboutusComponent } from './aboutus/aboutus.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { CommercialPaintingComponent } from './commercial-painting/commercial-painting.component';
import { IndustrialPaintingComponent } from './industrial-painting/industrial-painting.component';
import { CommercialBuildingMaintenanceComponent } from './commercial-building-maintenance/commercial-building-maintenance.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

export const AppRoutes: Routes = [

  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about-us',
    component: AboutusComponent
  },
  {
    path: 'contact', 
    component: ContactComponent
  },
  {
    path: 'commercial-painting',
    component: CommercialPaintingComponent
  },
  {
    path: 'industrial-painting',
    component: IndustrialPaintingComponent
  },
  {
    path: 'commercial-building-maintenance',
    component: CommercialBuildingMaintenanceComponent
  },
  {
    path: 'thank-you',
    component: ThankYouComponent
  },
  { path: '**', 
    component: PageNotFoundComponent
  }

];
