import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../common/common.service';
import { NgForm } from '@angular/forms';
declare var $: any;
declare var overlayScrollbars: any;
import { Meta, Title } from '@angular/platform-browser';
import { appSetting } from '../app.config';
import { environment } from "../../environments/environment";
import { BreadcrumbService } from "../common/breadcrumb.service";

@Component({
    selector: 'app-aboutus',
    templateUrl: './aboutus.component.html',
    styleUrls: ['./aboutus.component.css']
})
export class AboutusComponent implements OnInit {
    public _frontUrl = environment._frontUrl;
    public _siteUrl = appSetting._siteUrl;
    constructor(private _meta: Meta, private _title: Title, private commonService: CommonService, private router: Router, private breadcrumbService: BreadcrumbService) {
        try {
            if (this.commonService.isBrowser()) {
                $(document).ready(function() {
                    window.history.pushState(null, "", window.location.href);
                    window.onpopstate = function() {
                        window.history.pushState(null, "", window.location.href);
                    };
                });
            }
            this.getSeo();
        } catch (error) {

        }

    }

    public options: any = { autoHide: false };
    public numbers = Array(50).fill(0);

    ngOnInit() {
        try {
            if (this.commonService.isBrowser()) {
                const breadcrumbs = [
                    { label: 'Home', link: '/' },
                    { label: 'About', link: '/about-us' }
                ];
                this.breadcrumbService.setBreadcrumbs(breadcrumbs);
                $('html,body').animate({ scrollTop: 0 }, 50);
                $('.navbar-toggler').one('click', function() {
                    $('#dppnav').removeAttr('style');
                })
                $('.navbar-toggler').click(function() {
                    $('.nav-mobi+div').removeClass('navhide');
                    $('.nav-mobi').toggleClass('makewhite');
                    $('.nav-mobi').toggleClass('animenu');
                    $('.navbar-nav').toggleClass('nav-expanded');
                });
                $('.nav-item .nav-link').click(function() {
                    $('.nav-item .nav-link').removeClass('active');
                    $(this).addClass('active');
                });
                $('body').on('click', 'button[aria-expanded ="true"]', function() {
                    setTimeout(function() {
                        $('.nav-mobi+div').addClass('navhide');
                    }, 400);
                });
                var windwidth = $(window).width();
                if (windwidth < 767) {
                    $('.navbar-nav .nav-item').removeClass('cont');
                } else {
                    $('.nav-item.dropdown').mouseenter(function() {
                        $(this).addClass('show');
                        $(this).find('.dropdown-menu').addClass('show');
                    });
                    $('.nav-item.dropdown').mouseleave(function() {
                        $(this).removeClass('show');
                        $(this).find('.dropdown-menu').removeClass('show');
                    });
                }
            }


        } catch (error) {

        }
    }

    getSeo() {
        try {
            this.commonService.callWpApi('pages/?slug=about-us', null, false, 'get').then(success => {
                if (success && success.length > 0 && success[0].status === 'publish') {
                    success = success[0]

                    this._title.setTitle(success.yoast_meta.yoast_wpseo_title);
                    this._meta.updateTag({ 'name': 'keywords', 'content': "D Prestige Painting Melbourne, Commercial painting Melbourne, Residential painting Melbourne, Melbourne painting company" });
                    this._meta.updateTag({ 'name': 'description', 'content': "Discover D Prestige Painting’s journey since 2005. Experts in commercial and residential painting across Melbourne, known for reliability, quality, and a 15-year guarantee." });

                    this._meta.updateTag({ "property": 'og:url', "content": this._siteUrl });
                    this._meta.updateTag({ "property": 'og:type', "content": "website" });

                    
                        this._meta.updateTag({ "property": 'og:title', "content": "About D Prestige Painting - Melbourne’s Trusted Painters Since 2005" });
                    
                        this._meta.updateTag({ "property": 'og:description', "content": "Discover D Prestige Painting’s journey since 2005. Experts in commercial and residential painting across Melbourne, known for reliability, quality, and a 15-year guarantee." });
                        this._meta.updateTag({ "property": 'og:image', "content": "https://d2lp59icjssuny.cloudfront.net/images/about-page-thumb.jpg" });
                    
                    this._meta.updateTag({ "name": 'og:image:width', "content": "1200" });
                    this._meta.updateTag({ "name": 'og:image:height', "content": "628" });


                        this._meta.updateTag({ "name": 'twitter:title', "content": "About D Prestige Painting - Melbourne’s Trusted Painters Since 2005" });
                        this._meta.updateTag({ "name": 'twitter:description', "content": "Discover D Prestige Painting’s journey since 2005. Experts in commercial and residential painting across Melbourne, known for reliability, quality, and a 15-year guarantee." });
                        this._meta.updateTag({ "name": 'twitter:image', "content": "https://d2lp59icjssuny.cloudfront.net/images/about-page-thumb.jpg" });
                    
                }
            });

        } catch (error) {
            console.log('error', error)
        }
    }

    openVideo(flag) {
        try {
            if (this.commonService.isBrowser()) {
                if (flag === 'open') {
                    $('.about-pop-video').fadeIn();
                } else {
                    $('.about-pop-video').fadeOut();
                }
            }

        } catch (error) {

        }
    }

}