import { Component, PLATFORM_ID, Inject, Injectable, NgZone, APP_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, of , Subject } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Router, NavigationEnd } from "@angular/router";
declare var window;
import { appSetting } from '../app.config';

@Injectable()
export class CommonService {
    authorised: any = false;
    constructor(private router: Router, public _http: HttpClient, @Inject(PLATFORM_ID) platformId: Object) {
        this.platformId = platformId;
        this.currentUrl = this.router.url;
        this._apiUrl = appSetting.apiUrl;
        this._apiWordpressUrl = appSetting._apiWordpressUrl;
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
            };
        });
    }

    public _apiUrl;
    public _apiWordpressUrl;
    public platformId;
    public dataArry: any;
    private previousUrl: string;
    private currentUrl: string;
    private missionAnnouncedSetting = new Subject < object > ();
    private missionConfirmedPopup = new Subject < object > ();
    private missionAnnouncedSettingTitle = new Subject < object > ();
    private missionAnnouncedSettingLoader = new Subject < object > ();
    private missionAnnouncedSource = new Subject < object > ();
    private missionConfirmedSource = new Subject < object > ();

    missionSetting$ = this.missionAnnouncedSetting.asObservable();
    missionPopup$ = this.missionConfirmedPopup.asObservable();
    missionSettingTitle$ = this.missionAnnouncedSettingTitle.asObservable();
    missionSettingLoder$ = this.missionAnnouncedSettingLoader.asObservable();
    missionAnnounced$ = this.missionAnnouncedSource.asObservable();
    missionConfirmed$ = this.missionConfirmedSource.asObservable();

    ChildToParent(mission: object) {
        this.missionAnnouncedSource.next(mission);
    }

    ParentToChild(astronaut: object) {
        this.missionConfirmedSource.next(astronaut);
    }

    ChildToSettingParent(mission: object) {
        this.missionAnnouncedSetting.next(mission);
    }

    ChildToSettingTitle(mission: object) {
        this.missionAnnouncedSettingTitle.next(mission);
    }

    ChildToSettingLoader(mission: object) {
        this.missionAnnouncedSettingLoader.next(mission);
    }

    ChildToSettingPopup(mission: object) {
        this.missionConfirmedPopup.next(mission);
    }

    setToken(key, value) {
        if (isPlatformBrowser(this.platformId)) {
            //console.log(key, value)
            var encryptData = btoa(btoa(btoa(btoa(btoa(value)))));
            localStorage.setItem(key, encryptData);
        }
    }
    getToken(key) {
        try {
            if (isPlatformBrowser(this.platformId)) {
                var decryptData = null;
                if (localStorage.getItem(key) && localStorage.getItem(key).length > 0) {
                    decryptData = atob(atob(atob(atob(atob(localStorage.getItem(key))))))
                }
                return decryptData;
            }

        } catch (error) {

        }

    }
    removeToken(key) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem(key);
        }
    }
    clearToken() {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.clear()
        }
    }

    getPreviousUrl() {
        return this.previousUrl;
    }


    /*******************************************************************************************
  @PURPOSE        :   Call api.
  @Parameters   :   {
              url : <url of api>
              data : <data object (JSON)>
              method : String (get, post)
              isForm (Optional) : Boolean - to call api with form data
              isPublic (Optional) : Boolean - to call api without auth header
            }
  /*****************************************************************************************/
    callApi(url, data, method, isPublic ? , isForm ? ): Promise < any > {
        let headers;
        if (isPublic) {
            headers = new HttpHeaders({ 'content-Type': 'application/json' });
        } else {
            headers = new HttpHeaders({ 'content-Type': 'application/json', 'Authorization': this.getToken('accessToken') });
        }
        if (isForm) {
            headers = new HttpHeaders({ 'Authorization': this.getToken('accessToken') });
        }
        return new Promise((resolve, reject) => {
            if (method == 'post') {
                this._http.post(this._apiUrl + 'api/' + url, data, { headers })
                    .subscribe(data => { resolve(data) }, error => { this.showServerError(error) })
            } else if (method == 'get') {
                this._http.get(this._apiUrl + 'api/' + url, { headers })
                    .subscribe(data => { resolve(data) }, error => { this.showServerError(error) })
            }
        })
    }

    callWpApi(url, data, setting, method): Promise < any > {
        try {
            let headers = new HttpHeaders({ 'content-Type': 'application/json' });
            var apiurl = (setting) ? this._apiWordpressUrl + 'acf/v3/options/' + url : this._apiWordpressUrl + 'wp/v2/' + url;
            return new Promise((resolve, reject) => {
                if (method == 'post') {
                    this._http.post(apiurl, data, { headers })
                        .subscribe(data => { resolve(data) }, error => { this.showServerError(error) })
                } else if (method == 'get') {
                    this._http.get(apiurl, { headers })
                        .subscribe(data => { resolve(data) }, error => { this.showServerError(error) });
                }
            })
        } catch (error) {

        }
    }

    showServerError(e) {

    }

    setData(data: any) {
        this.dataArry = '';
        this.dataArry = data;
    }

    getData() {
        return this.dataArry;
    }

    isBrowser() {
        try {
            if (isPlatformBrowser(this.platformId)) {
                return true;
            } else {
                return false;
            }
        } catch (e) {

        }
    }


}