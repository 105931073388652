import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../common/common.service';
import { NgForm } from '@angular/forms';
declare var $: any;
import { Meta, Title } from '@angular/platform-browser';
import { appSetting } from '../app.config';
import { environment } from "../../environments/environment";
import { BreadcrumbService } from "../common/breadcrumb.service";

@Component({
    selector: 'app-commercial-building-maintenance',
    templateUrl: './commercial-building-maintenance.component.html',
    styleUrls: ['./commercial-building-maintenance.component.css']
})
export class CommercialBuildingMaintenanceComponent implements OnInit {
    public _frontUrl = environment._frontUrl;
    public _siteUrl = appSetting._siteUrl;
    constructor(private _meta: Meta, private _title: Title, private commonService: CommonService, private breadcrumbService: BreadcrumbService) {
        if (this.commonService.isBrowser()) {
            $(document).ready(function() {
                window.history.pushState(null, "", window.location.href);
                window.onpopstate = function() {
                    window.history.pushState(null, "", window.location.href);
                };
            });
        }

        this.getSeo();
    }

    ngOnInit() {
        try {
            if (this.commonService.isBrowser()) {
                const breadcrumbs = [
                    { label: 'Home', link: '/' },
                    { label: 'Melbourne Commercial Building Maintenance Services', link: '/commercial-building-maintenance' }
                ];
                this.breadcrumbService.setBreadcrumbs(breadcrumbs);
                $('html,body').animate({ scrollTop: 0 }, 50);
                $('.navbar-toggler').one('click', function() {
                    $('#dppnav').removeAttr('style');
                })
                $('.navbar-toggler').click(function() {
                    $('.nav-mobi+div').removeClass('navhide');
                    $('.nav-mobi').toggleClass('makewhite');
                    $('.nav-mobi').toggleClass('animenu');
                    $('.navbar-nav').toggleClass('nav-expanded');
                });
                $('.nav-item .nav-link').click(function() {
                    $('.nav-item .nav-link').removeClass('active');
                    $(this).addClass('active');
                });
                $('body').on('click', 'button[aria-expanded ="true"]', function() {
                    setTimeout(function() {
                        $('.nav-mobi+div').addClass('navhide');
                    }, 400);
                });
                var windwidth = $(window).width();
                if (windwidth < 767) {
                    $('.navbar-nav .nav-item').removeClass('cont');
                } else {
                    $('.nav-item.dropdown').mouseenter(function() {
                        $(this).addClass('show');
                        $(this).find('.dropdown-menu').addClass('show');
                    });
                    $('.nav-item.dropdown').mouseleave(function() {
                        $(this).removeClass('show');
                        $(this).find('.dropdown-menu').removeClass('show');
                    });
                }

            }

        } catch (error) {

        }
    }

    getSeo() {
        try {
            this.commonService.callWpApi('pages/?slug=commercial-building', null, false, 'get').then(success => {
                if (success && success.length > 0 && success[0].status === 'publish') {
                    success = success[0]

                    this._title.setTitle(success.yoast_meta.yoast_wpseo_title);
                    this._meta.updateTag({ 'name': 'keywords', 'content': success.yoast_meta.yoast_wpseo_metakeywords });
                    this._meta.updateTag({ 'name': 'description', 'content': success.yoast_meta.yoast_wpseo_metadesc });

                    this._meta.updateTag({ "property": 'og:url', "content": this._siteUrl });
                    this._meta.updateTag({ "property": 'og:type', "content": 'website' });

                    if (success.yoast_meta.yoast_wpseo_opengraph_title && success.yoast_meta.yoast_wpseo_opengraph_title.length > 0) {
                        this._meta.updateTag({ "property": 'og:title', "content": success.yoast_meta.yoast_wpseo_opengraph_title });
                    }
                    if (success.yoast_meta.yoast_wpseo_opengraph_description && success.yoast_meta.yoast_wpseo_opengraph_description.length > 0) {
                        this._meta.updateTag({ "property": 'og:description', "content": success.yoast_meta.yoast_wpseo_opengraph_description });
                    }
                    if (success.yoast_meta.yoast_wpseo_opengraph_image && success.yoast_meta.yoast_wpseo_opengraph_image.length > 0) {
                        this._meta.updateTag({ "property": 'og:image', "content": success.yoast_meta.yoast_wpseo_opengraph_image });
                    }

                    this._meta.updateTag({ "name": 'og:image:width', "content": '1200' });
                    this._meta.updateTag({ "name": 'og:image:height', "content": '633' });


                    if (success.yoast_meta.yoast_wpseo_twitter_title && success.yoast_meta.yoast_wpseo_twitter_title.length > 0) {
                        this._meta.updateTag({ "name": 'twitter:title', "content": success.yoast_meta.yoast_wpseo_twitter_title });
                    }
                    if (success.yoast_meta.yoast_wpseo_twitter_description && success.yoast_meta.yoast_wpseo_twitter_description.length > 0) {
                        this._meta.updateTag({ "name": 'twitter:description', "content": success.yoast_meta.yoast_wpseo_twitter_description });
                    }
                    if (success.yoast_meta.yoast_wpseo_twitter_image && success.yoast_meta.yoast_wpseo_twitter_image.length > 0) {
                        this._meta.updateTag({ "name": 'twitter:image', "content": success.yoast_meta.yoast_wpseo_twitter_image });
                    }
                }
            });

        } catch (error) {
            console.log('error', error)
        }
    }
}