import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../common/common.service';
import { NgForm } from '@angular/forms';
declare var $: any;
import { Meta, Title } from '@angular/platform-browser';
import { appSetting } from '../app.config';
import { environment } from "../../environments/environment";
import { BreadcrumbService } from "../common/breadcrumb.service";

@Component({
    selector: 'app-industrial-painting',
    templateUrl: './industrial-painting.component.html',
    styleUrls: ['./industrial-painting.component.css']
})
export class IndustrialPaintingComponent implements OnInit {
    public _frontUrl = environment._frontUrl;
    public _siteUrl = appSetting._siteUrl;
    constructor(private _meta: Meta, private _title: Title, private commonService: CommonService, private breadcrumbService: BreadcrumbService) {
        if (this.commonService.isBrowser()) {
            $(document).ready(function() {
                window.history.pushState(null, "", window.location.href);
                window.onpopstate = function() {
                    window.history.pushState(null, "", window.location.href);
                };
            });
        }

        this.getSeo()
    }

    ngOnInit() {
        try {
            if (this.commonService.isBrowser()) {
                const breadcrumbs = [
                    { label: 'Home', link: '/' },
                    { label: 'Melbourne Industrial Painting Services', link: '/industrial-painting' }
                ];
                this.breadcrumbService.setBreadcrumbs(breadcrumbs);
                $('html,body').animate({ scrollTop: 0 }, 50);
                $('.navbar-toggler').one('click', function() {
                    $('#dppnav').removeAttr('style');
                })
                $('.navbar-toggler').click(function() {
                    $('.nav-mobi+div').removeClass('navhide');
                    $('.nav-mobi').toggleClass('makewhite');
                    $('.nav-mobi').toggleClass('animenu');
                    $('.navbar-nav').toggleClass('nav-expanded');
                });
                $('.nav-item .nav-link').click(function() {
                    $('.nav-item .nav-link').removeClass('active');
                    $(this).addClass('active');
                });
                $('body').on('click', 'button[aria-expanded ="true"]', function() {
                    setTimeout(function() {
                        $('.nav-mobi+div').addClass('navhide');
                    }, 400);
                });
                var windwidth = $(window).width();
                if (windwidth < 767) {
                    $('.navbar-nav .nav-item').removeClass('cont');
                } else {
                    $('.nav-item.dropdown').mouseenter(function() {
                        $(this).addClass('show');
                        $(this).find('.dropdown-menu').addClass('show');
                    });
                    $('.nav-item.dropdown').mouseleave(function() {
                        $(this).removeClass('show');
                        $(this).find('.dropdown-menu').removeClass('show');
                    });
                }

            }

        } catch (error) {

        }
    }

    getSeo() {
        try {
            this.commonService.callWpApi('pages/?slug=industry-painting', null, false, 'get').then(success => {
                if (success && success.length > 0 && success[0].status === 'publish') {
                    success = success[0]

                    this._title.setTitle(success.yoast_meta.yoast_wpseo_title);
                    this._meta.updateTag({ 'name': 'keywords', 'content': "D Prestige Painting Melbourne, Industrial painting Melbourne, Durable industrial painting, Large-scale industrial painting Melbourne" });
                    this._meta.updateTag({ 'name': 'description', 'content': "Durable and professional industrial painting in Melbourne by D Prestige Painting. From small to large-scale projects, we deliver long-lasting results with a 15-year guarantee." });

                    this._meta.updateTag({ "property": 'og:url', "content": this._siteUrl });
                    this._meta.updateTag({ "property": 'og:type', "content": "website" });

                    
                        this._meta.updateTag({ "property": 'og:title', "content": "D Prestige Painting - Melbourne’s Industrial Painting Experts" });
                        this._meta.updateTag({ "property": 'og:description', "content": "Durable and professional industrial painting in Melbourne by D Prestige Painting. From small to large-scale projects, we deliver long-lasting results with a 15-year guarantee." });
                        this._meta.updateTag({ "property": 'og:image', "content": "https://d2lp59icjssuny.cloudfront.net/images/" });
                    
                    this._meta.updateTag({ "name": 'og:image:width', "content": "1200" });
                    this._meta.updateTag({ "name": 'og:image:height', "content": "628" });

                        this._meta.updateTag({ "name": 'twitter:title', "content": "D Prestige Painting - Melbourne’s Industrial Painting Experts" });
                        this._meta.updateTag({ "name": 'twitter:description', "content": "Durable and professional industrial painting in Melbourne by D Prestige Painting. From small to large-scale projects, we deliver long-lasting results with a 15-year guarantee." });
                        this._meta.updateTag({ "name": 'twitter:image', "content": "" });
                    
                }
            });

        } catch (error) {
            console.log('error', error)
        }
    }

}