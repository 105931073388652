import { PLATFORM_ID, Inject, Injectable  } from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import { Router, NavigationEnd } from "@angular/router";
import {environment} from "../../environments/environment";
import {Subject} from "rxjs";
import {Meta, Title} from "@angular/platform-browser";
import { Breadcrumb } from '../breadcrumb/helpers/breadcrumb.interface';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbs: Breadcrumb[] = [];

  getBreadcrumbs(): Breadcrumb[] {
    return this.breadcrumbs;
  }

  setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this.breadcrumbs = breadcrumbs;
  }
}
