import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../common/common.service';
import { NgForm } from '@angular/forms';
declare var $: any;
import { Meta, Title } from '@angular/platform-browser';
import { appSetting } from '../app.config';
import { environment } from "../../environments/environment";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    public _frontUrl = environment._frontUrl;
    currentYear: number;
    constructor(private commonService: CommonService, private router: Router) {
        if (this.commonService.isBrowser()) {
            $(document).ready(function() {
                window.history.pushState(null, "", window.location.href);
                window.onpopstate = function() {
                    window.history.pushState(null, "", window.location.href);
                };
            });
        }
    }

    public contactForm: any = {};
    public formGroup: any;
    public showSucal: boolean = false

    ngOnInit() {
        try {
            if (this.commonService.isBrowser()) {
                this.currentYear = new Date().getFullYear();
                $('html,body').animate({ scrollTop: 0 }, 50);
                $('.rq-li-com').on('click', function() {
                    $('.rq-li-com').removeClass('active');
                    $(this).addClass('active');
                });
                this.autosize();
            }
        } catch (error) {

        }
    }

    autosize() {
        var text = $('textarea#message');

        text.each(function() {
            $(this).attr('rows', 1);
            resize($(this));
        });

        text.on('input', function() {
            resize($(this));
        });

        function resize($text) {
            $text.css('height', 'auto');
            $text.css('height', $text[0].scrollHeight + 'px');
        }
    }

    ngAfterViewInit() {
        var self = this;
        try {

        } catch (error) {
            console.log("error", error)
        }
    }

    openPopUp(flag) {
        try {
            if (this.commonService.isBrowser()) {
                if (flag === 'open') {
                    $('body').addClass('ohidden');
                    $('.request-quote-popup').addClass('triggered');
                } else {
                    $('body').removeClass('ohidden');
                    $('.request-quote-popup').removeClass('triggered');
                }
            }

        } catch (error) {

        }
    }

    detectMob() {
        try {
            if (this.commonService.isBrowser()) {
                if (navigator.userAgent.match(/Android/i) ||
                    navigator.userAgent.match(/webOS/i) ||
                    navigator.userAgent.match(/iPhone/i) ||
                    navigator.userAgent.match(/iPad/i) ||
                    navigator.userAgent.match(/iPod/i) ||
                    navigator.userAgent.match(/BlackBerry/i) ||
                    navigator.userAgent.match(/Windows Phone/i)
                ) {

                    return true;
                } else {
                    return false;
                }
            }
        } catch (error) {

        }
    }

    submitForm(f: NgForm) {
        this.formGroup = f;
        //console.log(f.valid)
        try {
            if (this.commonService.isBrowser()) {
                if (f.valid) {

                    f.value['form_type'] = 'quote';
                    this.commonService.callApi('send_dpp_contact_email', f.value, 'post', true).then(success => {
                        //console.log("success contact", success)
                        if (success.status == 1) {
                            if (this.formGroup) {
                                this.formGroup.resetForm();
                            }
                            this.showSucal = true;
                            setTimeout($.proxy(function() {
                                this.showSucal = false;
                            }, this), 3000)
                        } else {}
                    });

                }

            }

        } catch (error) {
            console.log('error', error)
        }
    }

}