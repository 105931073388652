export class appSetting {
   static apiUrl: string = 'https://stagingapi.jabri.co/'; //'http://localhost:5000/'; //'https://api.jabri.co/' // https://stagingapi.jabri.co/
   static _apiWordpressUrl: string = 'https://dppapi.dppainting.com.au/wp-json/'; //'http://localhost:5000/'; //'https://api.jabri.co/' // https://stagingapi.jabri.co/
   static _siteUrl: string = 'https://www.dppainting.com.au/'; //'http://localhost:5000/'; //'https://api.jabri.co/' // https://stagingapi.jabri.co/
   static live : boolean = true; 
}


// App Live

// export class appSetting {
//      static apiUrl: string = ''; //'http://localhost:5000/'; //'https://api.jabri.co/' // https://stagingapi.jabri.co/
//      static _apiWordpressUrl: string = 'https://dppapi.dppainting.com.au/wp-json/'; //'http://localhost:5000/'; //'https://api.jabri.co/' // https://stagingapi.jabri.co/
//      static _siteUrl: string = 'https://www.dppainting.com.au/'; //'http://localhost:5000/'; //'https://api.jabri.co/' // https://stagingapi.jabri.co/
//      static live : boolean = true;
// }
