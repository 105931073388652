import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppComponent } from './app.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AppRoutes } from './app.routing';
import { CommonService } from './common/common.service';
import { ContactComponent } from './contact/contact.component';
import { CommercialPaintingComponent } from './commercial-painting/commercial-painting.component';
import { IndustrialPaintingComponent } from './industrial-painting/industrial-painting.component';
import { CommercialBuildingMaintenanceComponent } from './commercial-building-maintenance/commercial-building-maintenance.component';
import { ProjectsComponent } from './projects/projects.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

@NgModule({
  declarations: [
    ThankYouComponent,
    AppComponent,
    AboutusComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    CommercialPaintingComponent,
    IndustrialPaintingComponent,
    CommercialBuildingMaintenanceComponent,
    ProjectsComponent,
    PageNotFoundComponent,
    BreadcrumbComponent
  ],
  imports: [
    NgSelectModule,
    BrowserModule,
    HttpModule,
     HttpClientModule,
     FormsModule,
    RouterModule.forRoot(AppRoutes, {onSameUrlNavigation: 'reload'})
  ],
  providers: [CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
