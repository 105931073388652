import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../common/common.service';
import { Meta, Title } from '@angular/platform-browser';
import { appSetting } from '../app.config';
import { environment } from "../../environments/environment";

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
    public _frontUrl = environment._frontUrl;
    public _siteUrl = appSetting._siteUrl;
    constructor(private _meta: Meta, private _title: Title, private commonService: CommonService) {

        this.getSeo()
    }

    getSeo() {
        try {
            this.commonService.callWpApi('pages/?slug=projects', null, false, 'get').then(success => {
                if (success && success.length > 0 && success[0].status === 'publish') {
                    success = success[0]

                    this._title.setTitle(success.yoast_meta.yoast_wpseo_title);
                    this._meta.updateTag({ 'name': 'keywords', 'content': success.yoast_meta.yoast_wpseo_metakeywords });
                    this._meta.updateTag({ 'name': 'description', 'content': success.yoast_meta.yoast_wpseo_metadesc });

                    this._meta.updateTag({ "property": 'og:url', "content": this._siteUrl });
                    this._meta.updateTag({ "property": 'og:type', "content": 'website' });

                    if (success.yoast_meta.yoast_wpseo_opengraph_title && success.yoast_meta.yoast_wpseo_opengraph_title.length > 0) {
                        this._meta.updateTag({ "property": 'og:title', "content": success.yoast_meta.yoast_wpseo_opengraph_title });
                    }
                    if (success.yoast_meta.yoast_wpseo_opengraph_description && success.yoast_meta.yoast_wpseo_opengraph_description.length > 0) {
                        this._meta.updateTag({ "property": 'og:description', "content": success.yoast_meta.yoast_wpseo_opengraph_description });
                    }
                    if (success.yoast_meta.yoast_wpseo_opengraph_image && success.yoast_meta.yoast_wpseo_opengraph_image.length > 0) {
                        this._meta.updateTag({ "property": 'og:image', "content": success.yoast_meta.yoast_wpseo_opengraph_image });
                    }

                    this._meta.updateTag({ "name": 'og:image:width', "content": '1200' });
                    this._meta.updateTag({ "name": 'og:image:height', "content": '633' });


                    if (success.yoast_meta.yoast_wpseo_twitter_title && success.yoast_meta.yoast_wpseo_twitter_title.length > 0) {
                        this._meta.updateTag({ "name": 'twitter:title', "content": success.yoast_meta.yoast_wpseo_twitter_title });
                    }
                    if (success.yoast_meta.yoast_wpseo_twitter_description && success.yoast_meta.yoast_wpseo_twitter_description.length > 0) {
                        this._meta.updateTag({ "name": 'twitter:description', "content": success.yoast_meta.yoast_wpseo_twitter_description });
                    }
                    if (success.yoast_meta.yoast_wpseo_twitter_image && success.yoast_meta.yoast_wpseo_twitter_image.length > 0) {
                        this._meta.updateTag({ "name": 'twitter:image', "content": success.yoast_meta.yoast_wpseo_twitter_image });
                    }
                }
            });

        } catch (error) {
            console.log('error', error)
        }
    }

    ngOnInit() {
        if (this.commonService.isBrowser()) {
            $('.projects-tile').click(function() {
                $('body').addClass('noscroll');
            });
            $('.project-popup-close').click(function() {
                $('body').removeClass('noscroll');
                $('.project-popup').fadeOut();
            });
            $('#projects-tile-1').click(function() {
                $('#projects-popup-1').fadeIn();
            });
            $('#projects-tile-2').click(function() {
                $('#projects-popup-2').fadeIn();
            });
            $('#projects-tile-3').click(function() {
                $('#projects-popup-3').fadeIn();
            });
            $('#projects-tile-4').click(function() {
                $('#projects-popup-4').fadeIn();
            });
            $('#projects-tile-5').click(function() {
                $('#projects-popup-5').fadeIn();
            });
            $('#projects-tile-6').click(function() {
                $('#projects-popup-6').fadeIn();
            });
            $('#projects-tile-7').click(function() {
                $('#projects-popup-7').fadeIn();
            });
            $('#projects-tile-8').click(function() {
                $('#projects-popup-8').fadeIn();
            });
            $('#projects-tile-9').click(function() {
                $('#projects-popup-9').fadeIn();
            });
            $('#projects-tile-10').click(function() {
                $('#projects-popup-10').fadeIn();
            });
            $('#projects-tile-11').click(function() {
                $('#projects-popup-11').fadeIn();
            });
            $('#projects-tile-12').click(function() {
                $('#projects-popup-12').fadeIn();
            });

            $('#projects-popup-1 .project-popup-prev').click(function() {
                if ($('#projects-popup-1 .project-popup-inner img.active').parent('picture').prev('picture').length > 0) {
                    $('#projects-popup-1 .project-popup-inner img.active').removeClass('active').parent('picture').prev('picture').find('img').addClass('active');
                    $('#projects-popup-1 .project-page-item.active').removeClass('active').prev('.project-page-item').addClass('active');
                    $('#projects-popup-1 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-1 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-1 .project-popup-inner img').first().hasClass('active')) {
                        $('#projects-popup-1 .project-popup-prev').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-1 .project-popup-next').click(function() {
                if ($('#projects-popup-1 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-1 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-1 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-1 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-1 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-1 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-1 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-2 .project-popup-prev').click(function() {
                if ($('#projects-popup-2 .project-popup-inner img.active').parent('picture').prev('picture').length > 0) {
                    $('#projects-popup-2 .project-popup-inner img.active').removeClass('active').parent('picture').prev('picture').find('img').addClass('active');
                    $('#projects-popup-2 .project-page-item.active').removeClass('active').prev('.project-page-item').addClass('active');
                    $('#projects-popup-2 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-2 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-2 .project-popup-inner img').first().hasClass('active')) {
                        $('#projects-popup-2 .project-popup-prev').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-2 .project-popup-next').click(function() {
                if ($('#projects-popup-2 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-2 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-2 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-2 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-2 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-2 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-2 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-3 .project-popup-prev').click(function() {
                if ($('#projects-popup-3 .project-popup-inner img.active').parent('picture').prev('picture').length > 0) {
                    $('#projects-popup-3 .project-popup-inner img.active').removeClass('active').parent('picture').prev('picture').find('img').addClass('active');
                    $('#projects-popup-3 .project-page-item.active').removeClass('active').prev('.project-page-item').addClass('active');
                    $('#projects-popup-3 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-3 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-3 .project-popup-inner img').first().hasClass('active')) {
                        $('#projects-popup-3 .project-popup-prev').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-3 .project-popup-next').click(function() {
                if ($('#projects-popup-3 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-3 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-3 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-3 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-3 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-3 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-3 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-4 .project-popup-prev').click(function() {
                if ($('#projects-popup-4 .project-popup-inner img.active').parent('picture').prev('picture').length > 0) {
                    $('#projects-popup-4 .project-popup-inner img.active').removeClass('active').parent('picture').prev('picture').find('img').addClass('active');
                    $('#projects-popup-4 .project-page-item.active').removeClass('active').prev('.project-page-item').addClass('active');
                    $('#projects-popup-4 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-4 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-4 .project-popup-inner img').first().hasClass('active')) {
                        $('#projects-popup-4 .project-popup-prev').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-4 .project-popup-next').click(function() {
                if ($('#projects-popup-4 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-4 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-4 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-4 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-4 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-4 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-4 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-5 .project-popup-prev').click(function() {
                if ($('#projects-popup-5 .project-popup-inner img.active').parent('picture').prev('picture').length > 0) {
                    $('#projects-popup-5 .project-popup-inner img.active').removeClass('active').parent('picture').prev('picture').find('img').addClass('active');
                    $('#projects-popup-5 .project-page-item.active').removeClass('active').prev('.project-page-item').addClass('active');
                    $('#projects-popup-5 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-5 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-5 .project-popup-inner img').first().hasClass('active')) {
                        $('#projects-popup-5 .project-popup-prev').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-5 .project-popup-next').click(function() {
                if ($('#projects-popup-5 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-5 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-5 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-5 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-5 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-5 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-5 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-6 .project-popup-prev').click(function() {
                if ($('#projects-popup-6 .project-popup-inner img.active').parent('picture').prev('picture').length > 0) {
                    $('#projects-popup-6 .project-popup-inner img.active').removeClass('active').parent('picture').prev('picture').find('img').addClass('active');
                    $('#projects-popup-6 .project-page-item.active').removeClass('active').prev('.project-page-item').addClass('active');
                    $('#projects-popup-6 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-6 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-6 .project-popup-inner img').first().hasClass('active')) {
                        $('#projects-popup-6 .project-popup-prev').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-6 .project-popup-next').click(function() {
                if ($('#projects-popup-6 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-6 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-6 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-6 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-6 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-6 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-6 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-7 .project-popup-prev').click(function() {
                if ($('#projects-popup-7 .project-popup-inner img.active').parent('picture').prev('picture').length > 0) {
                    $('#projects-popup-7 .project-popup-inner img.active').removeClass('active').parent('picture').prev('picture').find('img').addClass('active');
                    $('#projects-popup-7 .project-page-item.active').removeClass('active').prev('.project-page-item').addClass('active');
                    $('#projects-popup-7 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-7 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-7 .project-popup-inner img').first().hasClass('active')) {
                        $('#projects-popup-7 .project-popup-prev').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-7 .project-popup-next').click(function() {
                if ($('#projects-popup-7 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-7 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-7 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-7 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-7 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-7 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-7 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-8 .project-popup-prev').click(function() {
                if ($('#projects-popup-8 .project-popup-inner img.active').parent('picture').prev('picture').length > 0) {
                    $('#projects-popup-8 .project-popup-inner img.active').removeClass('active').parent('picture').prev('picture').find('img').addClass('active');
                    $('#projects-popup-8 .project-page-item.active').removeClass('active').prev('.project-page-item').addClass('active');
                    $('#projects-popup-8 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-8 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-8 .project-popup-inner img').first().hasClass('active')) {
                        $('#projects-popup-8 .project-popup-prev').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-8 .project-popup-next').click(function() {
                if ($('#projects-popup-8 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-8 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-8 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-8 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-8 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-8 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-8 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-9 .project-popup-prev').click(function() {
                if ($('#projects-popup-9 .project-popup-inner img.active').parent('picture').prev('picture').length > 0) {
                    $('#projects-popup-9 .project-popup-inner img.active').removeClass('active').parent('picture').prev('picture').find('img').addClass('active');
                    $('#projects-popup-9 .project-page-item.active').removeClass('active').prev('.project-page-item').addClass('active');
                    $('#projects-popup-9 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-9 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-9 .project-popup-inner img').first().hasClass('active')) {
                        $('#projects-popup-9 .project-popup-prev').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-9 .project-popup-next').click(function() {
                if ($('#projects-popup-9 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-9 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-9 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-9 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-9 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-9 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-9 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-10 .project-popup-next').click(function() {
                if ($('#projects-popup-10 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-10 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-10 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-10 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-10 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-10 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-10 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-11 .project-popup-next').click(function() {
                if ($('#projects-popup-11 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-11 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-11 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-11 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-11 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-11 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-11 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });

            $('#projects-popup-12 .project-popup-next').click(function() {
                if ($('#projects-popup-12 .project-popup-inner img.active').parent('picture').next('picture').length > 0) {
                    $('#projects-popup-12 .project-popup-inner img.active').removeClass('active').parent('picture').next('picture').find('img').addClass('active');
                    $('#projects-popup-12 .project-page-item.active').removeClass('active').next('.project-page-item').addClass('active');
                    $('#projects-popup-12 .project-popup-prev').removeClass('project-popup-navdis');
                    $('#projects-popup-12 .project-popup-next').removeClass('project-popup-navdis');
                    if ($('#projects-popup-12 .project-popup-inner img').last().hasClass('active')) {
                        $('#projects-popup-12 .project-popup-next').addClass('project-popup-navdis');
                    }
                }
            });


        }

    }

}