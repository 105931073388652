import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../common/common.service';
import { NgForm } from '@angular/forms';
declare var $: any;
import { Meta, Title } from '@angular/platform-browser';
import { appSetting } from '../app.config';
import { environment } from "../../environments/environment";
import { BreadcrumbService } from "../common/breadcrumb.service";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
    public _frontUrl = environment._frontUrl;
    public _siteUrl = appSetting._siteUrl;
    public isLoading =false;
    public cars = [
        { id: 1, name: 'Volvo' },
        { id: 2, name: 'Saab' },
        { id: 3, name: 'Opel' },
        { id: 4, name: 'Audi' },
    ];
    constructor(private _meta: Meta, private _title: Title, private commonService: CommonService, private router: Router, private breadcrumbService: BreadcrumbService) {
        if (this.commonService.isBrowser()) {
            $(document).ready(function() {
                window.history.pushState(null, "", window.location.href);
                window.onpopstate = function() {
                    window.history.pushState(null, "", window.location.href);
                };
            });
        }

        this.getSeo()
    }

    public contactForm: any = {};
    public formGroup: any;

    public showSucal: boolean = false;

    ngOnInit() {
        try {
            if (this.commonService.isBrowser()) {
                const breadcrumbs = [
                    { label: 'Home', link: '/' },
                    { label: 'Contact', link: '/contact' }
                ];
                this.breadcrumbService.setBreadcrumbs(breadcrumbs);
                $('html,body').animate({ scrollTop: 0 }, 50);
                $('.navbar-toggler').one('click', function() {
                    $('#dppnav').removeAttr('style');
                })
                $('.navbar-toggler').click(function() {
                    $('.nav-mobi+div').removeClass('navhide');
                    $('.nav-mobi').toggleClass('makewhite');
                    $('.nav-mobi').toggleClass('animenu');
                    $('.navbar-nav').toggleClass('nav-expanded');
                });
                $('.nav-item .nav-link').click(function() {
                    $('.nav-item .nav-link').removeClass('active');
                    $(this).addClass('active');
                });
                $('body').on('click', 'button[aria-expanded ="true"]', function() {
                    setTimeout(function() {
                        $('.nav-mobi+div').addClass('navhide');
                    }, 400);
                });
                var windwidth = $(window).width();
                if (windwidth < 767) {
                    $('.navbar-nav .nav-item').removeClass('cont');
                } else {
                    $('.nav-item.dropdown').mouseenter(function() {
                        $(this).addClass('show');
                        $(this).find('.dropdown-menu').addClass('show');
                    });
                    $('.nav-item.dropdown').mouseleave(function() {
                        $(this).removeClass('show');
                        $(this).find('.dropdown-menu').removeClass('show');
                    });
                }

            }

        } catch (error) {

        }
    }

    getSeo() {
        try {
            this.commonService.callWpApi('pages/?slug=contact', null, false, 'get').then(success => {
                if (success && success.length > 0 && success[0].status === 'publish') {
                    success = success[0]
                    this._title.setTitle(success.yoast_meta.yoast_wpseo_title);
                    this._meta.updateTag({ 'name': 'keywords', 'content': success.yoast_meta.yoast_wpseo_metakeywords });
                    this._meta.updateTag({ 'name': 'description', 'content': success.yoast_meta.yoast_wpseo_metadesc });

                    this._meta.updateTag({ "property": 'og:url', "content": this._siteUrl });
                    this._meta.updateTag({ "property": 'og:type', "content": 'website' });

                    if (success.yoast_meta.yoast_wpseo_opengraph_title && success.yoast_meta.yoast_wpseo_opengraph_title.length > 0) {
                        this._meta.updateTag({ "property": 'og:title', "content": success.yoast_meta.yoast_wpseo_opengraph_title });
                    }
                    if (success.yoast_meta.yoast_wpseo_opengraph_description && success.yoast_meta.yoast_wpseo_opengraph_description.length > 0) {
                        this._meta.updateTag({ "property": 'og:description', "content": success.yoast_meta.yoast_wpseo_opengraph_description });
                    }
                    if (success.yoast_meta.yoast_wpseo_opengraph_image && success.yoast_meta.yoast_wpseo_opengraph_image.length > 0) {
                        this._meta.updateTag({ "property": 'og:image', "content": success.yoast_meta.yoast_wpseo_opengraph_image });
                    }

                    this._meta.updateTag({ "name": 'og:image:width', "content": '1200' });
                    this._meta.updateTag({ "name": 'og:image:height', "content": '633' });

                    if (success.yoast_meta.yoast_wpseo_twitter_title && success.yoast_meta.yoast_wpseo_twitter_title.length > 0) {
                        this._meta.updateTag({ "name": 'twitter:title', "content": success.yoast_meta.yoast_wpseo_twitter_title });
                    }
                    if (success.yoast_meta.yoast_wpseo_twitter_description && success.yoast_meta.yoast_wpseo_twitter_description.length > 0) {
                        this._meta.updateTag({ "name": 'twitter:description', "content": success.yoast_meta.yoast_wpseo_twitter_description });
                    }
                    if (success.yoast_meta.yoast_wpseo_twitter_image && success.yoast_meta.yoast_wpseo_twitter_image.length > 0) {
                        this._meta.updateTag({ "name": 'twitter:image', "content": success.yoast_meta.yoast_wpseo_twitter_image });
                    }
                }
            });

        } catch (error) {
            console.log('error', error)
        }
    }

    submitForm(f: NgForm) {
        this.formGroup = f;
        //console.log(f.valid)
        try {
            if (this.commonService.isBrowser()) {
                if (f.valid) {
                    this.isLoading=true
                    f.value['form_type'] = 'contact';
                    this.commonService.callApi('send_dpp_contact_email', f.value, 'post', true).then(success => {
                        //console.log("success contact", success)
                        console.log(f.value)
                        sessionStorage.setItem("thk", f.value['name']);
                        if (success.status == 1) {
                            if (this.formGroup) {
                                this.formGroup.resetForm();
                            }
                            this.isLoading=false
                            this.router.navigate(['/thank-you']);  
                            this.showSucal = true;
                            setTimeout($.proxy(function() {
                                this.showSucal = false;
                            }, this), 3000)
                        }  
                        this.isLoading=false
                    });

                }
            }

        } catch (error) {
            this.isLoading=false
            console.log('error', error)
        }
    }

}