import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../common/common.service';
import { NgForm } from '@angular/forms';
declare var $: any;
import { Meta, Title } from '@angular/platform-browser';
import { appSetting } from '../app.config';
import { environment } from "../../environments/environment";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    public _frontUrl = environment._frontUrl;
    public _siteUrl = appSetting._siteUrl;
    constructor(private _meta: Meta, private _title: Title, private commonService: CommonService, private router: Router) {
        if (this.commonService.isBrowser()) {
            $(document).ready(function() {
                window.history.pushState(null, "", window.location.href);
                window.onpopstate = function() {
                    window.history.pushState(null, "", window.location.href);
                };
            });
        }
    }

    public contactForm: any = {};
    public formGroup: any;

    public showMobMenu: boolean = false;
    public homeact: boolean = false;
    public aboutact: boolean = false;
    public contactact: boolean = false;
    public servicemact: boolean = false;
    public servoneact: boolean = false;
    public servtwoact: boolean = false;
    public servtreact: boolean = false;

    ngOnInit() {


    }

    showMenu() {
        try {
            if (this.commonService.isBrowser()) {
                //console.log('11111111')
                $('#sitenav').removeAttr('style');
                if (this.showMobMenu) {
                    setTimeout($.proxy(function() {
                        $('body').removeClass('nav-noscroll');
                        $('.nav-mobi').removeClass('makewhite');
                        $('.nav-mobi').removeClass('animenu');
                        $('.navbar-nav').removeClass('nav-expanded');
                        $('.navbar').removeClass('nfixx');
                        this.showMobMenu = false;
                    }, this), 10);
                    setTimeout(function() {
                        $('.nav-mobi+div').addClass('navhide');
                    }, 400);
                } else {
                    setTimeout($.proxy(function() {
                        $('.nav-mobi+div').removeClass('navhide');
                        $('body').addClass('nav-noscroll');
                        $('.nav-mobi').addClass('makewhite');
                        $('.nav-mobi').addClass('animenu');
                        $('.navbar-nav').addClass('nav-expanded');
                        $('.navbar').addClass('nfixx');
                        this.showMobMenu = true;
                    }, this), 100);
                }

            }

        } catch (error) {

        }
    }

    openPopUp(flag) {
        try {

            if (this.commonService.isBrowser()) {
                if (flag === 'open') {
                    $('body').addClass('ohidden');
                    $('.request-quote-popup').addClass('triggered');
                } else {
                    $('body').removeClass('ohidden');
                    $('.request-quote-popup').removeClass('triggered');
                }
            }

        } catch (error) {

        }
    }

    goToHome() {
        if (this.commonService.isBrowser()) {
            $('html,body').animate({ scrollTop: 0 }, 500);
        }
    }

    submitForm(f: NgForm) {
        this.formGroup = f;
        //console.log(f.valid)
        try {
            if (f.valid) {

                f.value['form_type'] = 'contact';
                //console.log(f.value)

            }
        } catch (error) {
            console.log('error', error)
        }
    }

}