import { Component, OnInit, Injector } from '@angular/core';
declare var $: any;
import { Meta, Title } from '@angular/platform-browser';
import { CommonService } from '../common/common.service';
import { BreadcrumbService } from "../common/breadcrumb.service";
import { environment } from "../../environments/environment";
import { Breadcrumb } from './helpers/breadcrumb.interface';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {
    breadcrumbs: Breadcrumb[] = [];

    constructor(private breadcrumbService: BreadcrumbService) {}

    ngOnInit() {
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    }
}